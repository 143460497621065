@import  "../../App.scss";


.search-merchant-div{
    margin: 2% !important;
    .merchant-info-single{
        background-color: #f7f7f7;
        padding: 1%;
        border-radius: 10px;
    }
}