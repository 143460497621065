@import "../App.scss";


.home-container{
    .box-head{
        margin: 2% 3% !important;
        .box-content{
            /* Created with https://www.css-gradient.com */
            background: #343434;
            background: -webkit-linear-gradient(bottom right, #343434, #656565);
            background: -moz-linear-gradient(bottom right, #343434, #656565);
            background: linear-gradient(to top left, #343434, #656565);
            text-align: center;
            padding: 4% 2%;
            border-radius: 10px;
            h2{
                font-size: 4em;
                color: white;
                font-weight: 500;
                color: #8c9cff
            }
            h3{
                color: white;
                font-weight: 300;
            }
        }
    }
}

.card {
    background-color: #f7f7f7;
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin-bottom: 10px;
  }
  
  .heading {
    font-weight: bold;
    color: #333;
  }
  